import {
  VERIFY_OTP,
  REQUEST_OTP,
  RESEND_OTP,
  LOGIN,
  LOG_OUT,
  RESET_PASSWORD,
  FORGET_VERIFY_OTP,
  CONTACT_US
} from '../action-types';
import { UPDATE_NOTIFICATION, SHOW_LOADING, UPDATE_USER } from '../mutations';
import { NOTIFICATION_MESSAGES } from '../constants';
import httpService from '../../network/http-service';
import { handleError } from '../global/actions';
import { removeCookie } from '../../utils/cookie-service';
import { API_ENDPOINT } from '../api-action';

export function login(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.login,
        data: payload
      });

      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });

        return response.data;
      }

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function contactUs(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.contactUs,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error' }
        });
        return;
      }
      dispatch({ type: UPDATE_NOTIFICATION, payload: false });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(CONTACT_US, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export const logOut = () => {
  return (dispatch) => {
    removeCookie('veriipro-emp-user');
    removeCookie('veriipro-emp-token');
    dispatch({ type: UPDATE_USER, payload: null });
    dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[LOG_OUT] });
  };
};

export const updateUser = (payload) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER, payload: payload });
  };
};

export function forgotPasswordOtpRequest(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.forgot_password_otp_resquest,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[REQUEST_OTP].success });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(REQUEST_OTP, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function forgotPasswordOtpResend(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.forgot_password_otp_resend,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[RESEND_OTP].success });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(RESEND_OTP, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function forgotPasswordOtpVerify(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.forgot_password_otp_verify,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: NOTIFICATION_MESSAGES[FORGET_VERIFY_OTP].success
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(VERIFY_OTP, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function forgotPasswordReset(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.password_reset,
        data: payload
      });

      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: NOTIFICATION_MESSAGES[RESET_PASSWORD].success
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(RESET_PASSWORD, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function registration(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.register,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }
      dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[REQUEST_OTP].success });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(REQUEST_OTP, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function registerOtpVerify(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.register_otp_verify,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[VERIFY_OTP].success });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(VERIFY_OTP, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function registerOtpResend(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.register_otp_resend,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[RESEND_OTP].success });
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(RESEND_OTP, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function verifyEmail(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.emailvalidate,
        data: payload
      });
      return response?.data;
    } catch (err) {
      return false;
    }
  };
}

export function loginOtpVerify(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.loginOTPVerify,
        data: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(VERIFY_OTP, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}
export function resendLoginOTP(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.resendsiginpotp,
        data: payload
      });
      if (response?.data?.error === true) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: 'Failed to send OTP. Please try logging in again' }
        });
        return false;
      }
      dispatch({ type: UPDATE_NOTIFICATION, payload: 'OTP sent successfully!' });
      return true;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(VERIFY_OTP, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

import moment from 'moment';
import { annualySalaryRange, hourlySalaryRange } from '../constants/constants';
import { maskCurrency } from './masking';
import routes from '../constants/routes';

export function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

export function getFromNowDateFormat(date) {
  const dateText = moment(date).fromNow();
  return dateText === 'a day ago' ? '1 day ago' : dateText;
}

export function formatDate(date, withTime) {
  return moment(date).format(`MMM DD, yyyy ${withTime ? 'hh:mm A' : ''}`);
}
export function shortFormatDate(date) {
  if (!date) return '';

  const dateArray = date.split('-');
  if (dateArray.length <= 1) return date;
  return moment(`${dateArray[0]}-${dateArray[0]}-${dateArray[1]}`).format('MMM yyyy');
}

export const copyToClip = (text) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  return true;
};

export const convertToSEO = (title) => {
  return title
    .trim()
    .replaceAll(' ', '-')
    .replaceAll('/', '-')
    .replaceAll(',', '')
    .toLocaleLowerCase();
};

export const getYearList = (start) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = start; i <= currentYear; i++) {
    years.push(i);
  }
  return years;
};

export const annualSalaryRangeOption = () => {
  const options = [];
  for (let i = annualySalaryRange.MIN_SALARY; i <= annualySalaryRange.MAX_SALARY; i += 10000) {
    options.push({ label: maskCurrency(i), value: i });
  }
  return options;
};
export const hourlySalaryRangeOption = () => {
  const options = [];
  for (let i = hourlySalaryRange.MIN_SALARY; i <= hourlySalaryRange.MAX_SALARY; i++) {
    options.push({ label: maskCurrency(i), value: i });
  }
  return options;
};

export const highLightWord = (_TEXT, keyword) => {
  if (keyword) {
    let searchTerms = Array.isArray(keyword)
      ? [...new Set(keyword)]
      : [...new Set(keyword.split(' '))];
    searchTerms = searchTerms.sort((a, b) => b.length - a.length);
    for (const text of searchTerms) {
      if (text.trim().length > 0) {
        const escapedText = text.trim().replace(/([.*+?^=!:$#{}()|\[\]\/\\])/g, '\\$1');
        const regex = new RegExp(`(?<!\\w)${escapedText}(?!\\w)`, 'gi');
        _TEXT = _TEXT?.replace(regex, (match) => `<mark>${match}</mark>`);
      }
    }
    return <span dangerouslySetInnerHTML={{ __html: _TEXT }} />;
  }
  return _TEXT;
};

export const removeExtraCharFromJobTitle = (str) => {
  if (!str || str.trim().length === 0) {
    return '';
  }
  return str.replace(/["()]/g, '').replace(/\s+(or|and|not)\s+/gi, ' ');
};

export const extractBooleanKeywords = (searchString) => {
  if (!searchString) return [];
  const quotedMatches = searchString.match(/"([^"]+)"/g) || [];
  let remainingQuery = searchString.replace(/"([^"]+)"/g, '') ?? '';

  let keywords = new Set();

  quotedMatches.forEach((part) => {
    const match = part.match(/"([^"]+)"/);
    keywords.add(match[1].trim());
  });
  remainingQuery
    .replace(/[()]/g, '')
    .trim()
    .split(/\s+/)
    .forEach((word) => {
      if (word) keywords.add(word.trim());
    });
  keywords.delete('AND');
  keywords.delete('OR');
  keywords.delete('NOT');
  return Array.from(keywords);
};

export const validateBracketAndCommas = (str) => {
  str = str.trim();
  const stack = [];

  if (str.length === 0) {
    return true;
  }

  for (const s of str) {
    if (s === '(' || s === ')' || s === '"') {
      if (s === ')') {
        if (stack.pop() !== '(') {
          return false;
        }
      } else if (s === '(') {
        stack.push(s);
      } else if (s === '"') {
        if (stack[stack.length - 1] === '"') {
          stack.pop();
        } else {
          stack.push(s);
        }
      }
    }
  }

  return stack.length === 0;
};

export const getIpCountry = async () => {
  try {
    const response = await fetch(routes.getIp);
    if (!response.ok) {
      return false;
    }
    let data;
    try {
      data = await response.json();
    } catch (jsonError) {
      return false;
    }
    return data?.data?.hasOwnProperty('hasAccess') ? data.data.hasAccess : false;
  } catch (e) {
    return false;
  }
};

export const getFileExtension = (url) => {
  const parts = url.split('.');
  return parts.length > 1 ? parts.pop() : '';
};

export const formatStartEndDate = (start, end, withBracket) => {
  if (!start && !end) {
    return '';
  }

  let str = withBracket ? '(' : '';

  if (start) {
    str += shortFormatDate(start);
  }

  if (start && end) {
    str += ' - ';
  }

  if (end) {
    str += shortFormatDate(end);
  }

  if (withBracket) {
    str += ')';
  }

  return str;
};

export const removeExtraSymbolFromSkill = (str) => {
  if (!str || str.trim().length === 0) {
    return '';
  }
  return str.trim().replace(/^•|•$/g, '').replace(/•/g, ' ');
};

export const getEducationLevel_Degree_combine = (level, degree) => {
  const shorterDegreeForm = {
    phd: 'Doctorate / PhD',
    beng: 'Bachelor',
    btech: 'Bachelor',
    mtech: 'Master',
    bsc: 'Bachelor',
    msc: 'Master',
    meng: 'Master',
    mba: 'Master',
    bba: 'Bachelor',
    ma: 'Master',
    ba: 'Bachelor',
    med: 'Master',
    bed: 'Bachelor',
    mphil: 'Master',
    mpa: 'Master',
    ms: 'Master'
  };

  const replaceShorterNameWithLonger = (str) => {
    if (!str || typeof str !== 'string') return str;

    const strPart = str.split(' ');
    if (strPart.length === 0) return str;

    const firstPartKey = strPart[0].toLowerCase().replace(/\./g, '');

    if (shorterDegreeForm[firstPartKey]) {
      strPart[0] = shorterDegreeForm[firstPartKey];
    }

    return strPart.join(' ');
  };

  function removeWord(word, wholeString) {
    let regex = new RegExp('\\b' + word + '\\b', 'gi'); // Regex to match the word exactly (case-insensitive)
    return wholeString
      .replace(regex, '')
      .replace(/\s{2,}/g, ' ')
      .trim(); // Remove the word and extra spaces
  }

  function removeRedundant(str1, str2) {
    let wordsArray = str1.toLowerCase().split(' ');
    let secondArray = str2.toLowerCase().split(' ');

    // Loop over wordsArray to modify secondArray
    wordsArray.forEach((word) => {
      if (word === '/') {
        return; // Skip "/" from processing
      }
      let count = 0;
      secondArray = secondArray.filter((item, index) => {
        if (item === word) {
          count++;
          return count === 1; // Keep only the first occurrence
        }
        return true; // Keep all other words
      });
    });

    // Join the array back into a string if needed
    let resultString = secondArray.join(' ');
    resultString = resultString.replace(/\s+/g, ' ').trim();

    // Remove "in" and "of" from the start and end of the string
    resultString = resultString.replace(/^\s*(in|of)\s*|\s*(in|of)\s*$/g, '').trim();

    // Check if both 'in' or 'of' appear near each other, and remove the redundant one
    resultString = resultString.replace(/\b(in|of)\b\s+\b(in|of)\b/g, '$1');

    resultString = resultString.toLowerCase();

    return resultString;
  }

  // this function will convert lowercase string to it's original case
  function matchCase(lowerCaseStr, originalStr) {
    const lowerArray = lowerCaseStr.split(' ');
    const originalArray = originalStr.split(' ');
    const caseMap = new Map();
    originalArray.forEach((word) => {
      if (word.toLowerCase() !== 'in') {
        caseMap.set(word.toLowerCase(), word.charAt(0).toUpperCase() + word.slice(1));
      }
    });
    const resultArray = lowerArray.map((word) => caseMap.get(word) || word);

    return resultArray.join(' ');
  }

  const generateString = (level, degree) => {
    degree = removeWord('degree', degree);
    const levelArr = level
      .split(' ')
      .map((l) => l.replace(/[\/,]+$/g, ''))
      .filter(Boolean);
    const degreeArr = degree.split(' ').filter((d) => d !== '/');
    let startInd = 0;
    levelArr.forEach((l) => {
      degreeArr.slice(startInd).forEach((d, index) => {
        if (d.toLowerCase().includes(l.toLowerCase())) {
          degreeArr[startInd + index] = `${level} in`;
          startInd = startInd + index + 1;
        }
      });
    });
    const uniqueValues = [...new Set(degreeArr)];
    let resultStr = uniqueValues.join(' ').trim();
    if (startInd === 0) {
      resultStr = `${level} in ${degree}`;
    }
    const result = removeRedundant(level, resultStr);
    return matchCase(result, `${level} ${degree}`);
  };

  if (level && degree) {
    degree = replaceShorterNameWithLonger(degree);
    return generateString(level, degree);
  }
  return level || degree;
};

export const removeEmptyKeys = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) =>
        value !== undefined &&
        value !== null &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)
    )
  );
};

export const isBooleanSearch = (query) => {
  if (typeof query !== 'string') return false;
  // Check for special characters used in boolean search
  const isBalanced = (query, char) => query.split(char).length - (1 % 2) === 0;

  if (/[\"']/.test(query)) {
    if (isBalanced(query, "'") || isBalanced(query, '"')) {
      return true;
    }
  }

  if (/\s(AND|OR|NOT)\s/i.test(query)) {
    return true;
  }

  return false;
};
